import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Link } from 'gatsby';
import Sidebar from 'components/sidebar';
import PostsLayout from 'components/posts-layout';
import PostFooter from 'components/post-footer';
import Image from 'components/image';
import SEO from 'components/seo';
import Links from 'components/links';
import { LinkLabel } from 'utils/constants';
import { postMap, slugify } from 'utils/post-map';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PostsLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Good Artists Copy, Great Artists Steal`}</h1>
    <p>{`I think that’s what Pablo “Big Paint” Picasso said.`}</p>
    <p>{`So Blushie, today i'm gonna show you how we channeled our inner Pablo by
stealing and remixing a tactic that a different startup used to close sales to
take the fund from $1.7m → $2.4m.`}</p>
    <p>{`Once you learn this little trick, you can use it whether you’re raising money,
trying to close sales, or really doing anything with email outreach.`}</p>
    <p><strong parentName="p">{`Now it’s time to pull that Picasso:`}</strong></p>
    <p>{`Ben slacked me about a tactic he saw Brex (a big, hot startup worth `}{`$`}{`2.5B+)
using to close sales`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1406px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "65.49999999999999%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAEDAgX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHt7mygw//EABgQAQEAAwAAAAAAAAAAAAAAAAIQEiEx/9oACAEBAAEFAjyDZKyn/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFxAAAwEAAAAAAAAAAAAAAAAAECAxUf/aAAgBAQAGPwLEg//EABwQAQACAQUAAAAAAAAAAAAAAAEAERAhMVFhof/aAAgBAQABPyGxqDoQTnDqX2EnZWP/2gAMAwEAAgADAAAAEGMP/8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQMBAT8Qp//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAECAQE/EIf/xAAcEAEAAgEFAAAAAAAAAAAAAAABABEQMUFRcfH/2gAIAQEAAT8QIhk1oonoSzkgAlU3Wwhp2XeP/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Brex Tactic Slack Convo",
          "title": "Brex Tactic Slack Convo",
          "src": "/static/3f0fadd06d7fcd0be63c33f42fc8d8ff/ceb06/brex-tactic-slack-convo.jpg",
          "srcSet": ["/static/3f0fadd06d7fcd0be63c33f42fc8d8ff/a6407/brex-tactic-slack-convo.jpg 400w", "/static/3f0fadd06d7fcd0be63c33f42fc8d8ff/a24e6/brex-tactic-slack-convo.jpg 800w", "/static/3f0fadd06d7fcd0be63c33f42fc8d8ff/ceb06/brex-tactic-slack-convo.jpg 1406w"],
          "sizes": "(max-width: 1406px) 100vw, 1406px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`The way Ben described it was basically, if Brex was targeting you as a prospect,
the sales rep would send you a forwarded email from the head of sales where he
would tell the rep that you’re a great fit and offer a discount`}</p>
    <p><strong parentName="p">{`I thought this was a genius approach by Brex because:`}</strong></p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`It feels special`}</strong>{` - it makes you feel like you’ve been handpicked for the
offer from someone with authority (like the Brex head of sales)`}</li>
      <li parentName="ol"><strong parentName="li">{`It feels raw`}</strong>{` - being able to see a whole thread feels raw and real and
not like a cold email at all`}</li>
      <li parentName="ol"><strong parentName="li">{`It feels friendly`}</strong>{` - all of the language in both of these emails and the
transparency is v friendly, way more than normal cold emails`}</li>
    </ol>
    <p><strong parentName="p">{`Here's what Brex's version looked like:`}</strong></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1600px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "67.25000000000001%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAIF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB3amgD//EABUQAQEAAAAAAAAAAAAAAAAAABAB/9oACAEBAAEFAmP/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAUEAEAAAAAAAAAAAAAAAAAAAAg/9oACAEBAAY/Al//xAAWEAEBAQAAAAAAAAAAAAAAAAABECH/2gAIAQEAAT8hLov/2gAMAwEAAgADAAAAEPPP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGxAAAwACAwAAAAAAAAAAAAAAAAERECExUWH/2gAIAQEAAT8Ql3fBPSD2qVThF0sf/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Brex Tactic Original Email",
          "title": "Brex Tactic Original Email",
          "src": "/static/296494992dcdb5ba5a5e0895b6cc4e19/04bec/brex-tactic-original-email.jpg",
          "srcSet": ["/static/296494992dcdb5ba5a5e0895b6cc4e19/a6407/brex-tactic-original-email.jpg 400w", "/static/296494992dcdb5ba5a5e0895b6cc4e19/a24e6/brex-tactic-original-email.jpg 800w", "/static/296494992dcdb5ba5a5e0895b6cc4e19/04bec/brex-tactic-original-email.jpg 1600w", "/static/296494992dcdb5ba5a5e0895b6cc4e19/ea462/brex-tactic-original-email.jpg 1922w"],
          "sizes": "(max-width: 1600px) 100vw, 1600px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p><strong parentName="p">{`Here's what our version looked like:`}</strong></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1600px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "67.25000000000001%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAIF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB3amgD//EABUQAQEAAAAAAAAAAAAAAAAAABAB/9oACAEBAAEFAmP/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAUEAEAAAAAAAAAAAAAAAAAAAAg/9oACAEBAAY/Al//xAAWEAEBAQAAAAAAAAAAAAAAAAABECH/2gAIAQEAAT8hLov/2gAMAwEAAgADAAAAEPPP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGxAAAwACAwAAAAAAAAAAAAAAAAERECExUWH/2gAIAQEAAT8Ql3fBPSD2qVThF0sf/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "brex tactic original email",
          "title": "brex tactic original email",
          "src": "/static/296494992dcdb5ba5a5e0895b6cc4e19/04bec/brex-tactic-original-email.jpg",
          "srcSet": ["/static/296494992dcdb5ba5a5e0895b6cc4e19/a6407/brex-tactic-original-email.jpg 400w", "/static/296494992dcdb5ba5a5e0895b6cc4e19/a24e6/brex-tactic-original-email.jpg 800w", "/static/296494992dcdb5ba5a5e0895b6cc4e19/04bec/brex-tactic-original-email.jpg 1600w", "/static/296494992dcdb5ba5a5e0895b6cc4e19/ea462/brex-tactic-original-email.jpg 1922w"],
          "sizes": "(max-width: 1600px) 100vw, 1600px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`We sent this to 70 people, got 30 responses, and closed 8. That's 24 hours of
work delivering `}{`$`}{`250K in value, if you're counting!`}</p>
    <p>{`Clearly, this tactic worked for brex and it worked for us.`}</p>
    <p>{`It’ll work for you too, whether you’re trying to fundraise, close more sales, or
do anything that involves outreach over email`}</p>
    <p><strong parentName="p">{`Blushie, when you see something, steal and try it out for yourself. You don’t
need to invent everything on your own to be a helluva artist. The good artists
copy, the great artists steal.`}</strong></p>
    <p>{`until tomorrow,`}<br parentName="p"></br>{`
`}{`shaan`}</p>
    <PostFooter prevButton={{
      text: postMap.sometimes_you_need_to_be_a_worker_ant.label,
      slug: slugify(postMap.sometimes_you_need_to_be_a_worker_ant.slug)
    }} nextButton={{
      text: postMap.hiring_a_good_operator_feels_like_magic.label,
      slug: slugify(postMap.hiring_a_good_operator_feels_like_magic.slug)
    }} mdxType="PostFooter" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      